.footer {
  padding: 30px 0;
  background: #333333;
  text-align: center;
}

.footer__link {
  color: #ffffff;
  font-size: 1.125rem;
  font-weight: 300;
}
